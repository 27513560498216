import bublina1 from '../../assets/svg/bublina1.svg'
import bublina2 from '../../assets/svg/bublina2.svg'
import bublina3 from '../../assets/svg/bublina3.svg'
import bublina4 from '../../assets/svg/bublina4.svg'

const Bublina = ({children, type, ...rest}) => {
    return (
        <div className={"bublina bublina"+type} {...rest} >
            {type===1&&<img src={bublina1} />}
            {type===2&&<img src={bublina2} />}
            {type===3&&<img src={bublina3} />}
            {type===4&&<img src={bublina4} />}
            <span>{children}</span>
        </div>
    )
}

export default Bublina